@import '../../../styles/customMediaQueries.css';

.root {
  overflow: hidden;
  width: 100%;
  max-width: 100vw;
  height: 70vh;
  display: flex;
  position: relative;
  align-items: center;

  @media (--viewportMedium) {
    height: 80vh;
  }
  @media (--viewportLarge) {
    height: 88vh;
  }
}
.root .container {
  margin-left: 0%;
  max-width: 1056px;
  width: 100%;
  background-color: #0b81d291;
  color: var(--colorWhite);
  padding: 12px 16px;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 9;
  @media (--viewportMedium) {
    width: 71%;
    border-radius: 16px;
    margin-left: 6%;
    padding: 12px 24px;
    align-items: flex-start;
  }

  @media (--viewportLargeWithPaddings) {
    width: 46%;
  }
}
.bannerTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  font-weight: 600;
  padding: 10px 0;
  margin-bottom: 24px;
  line-height: 38px;
  @media (--viewportMedium) {
    margin-bottom: 30px;
    justify-content: flex-start;
  }
}
.popularsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
  margin-top: 12px;
  @media (--viewportMedium) {
    flex-direction: row;
    margin-top: 0;
    gap: 12px;
  }
}

.popularsContainer p {
  color: white;
  font-size: 16px;
  margin: 0;
  padding: 0;
}
.popularsContainer ul {
  display: flex;
  flex-direction: column;
  gap: 6px;
  @media (--viewportMedium) {
    flex-direction: row;
    gap: 8px;
  }
}
.popularsContainer ul li {
  background-color: #176595de;
  border-radius: 8px;
  padding: 6px 12px;
  border: 1px solid var(--colorWhite);
  font-size: 13px;

  @media (min-width: 1284px) {
    font-size: 15px;
  }
  &:hover {
    cursor: pointer;
    background-color: #176595de;
    color: var(--colorWhite);
    border: 1px solid #176595de;
    box-shadow: var(--boxShadowButton);
  }
}
.popularsContainer ul li a {
  &:hover {
    color: var(--colorWhite);
  }
}
.popularsItem {
  text-decoration: none;
  color: var(--colorWhite);
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColor);
  }
}
.sliderProducts {
  position: absolute !important;
  width: 100%;
  max-width: 100%;
  height: 100%;
  @media (--viewportMedium) {
    height: calc(100vh - 100px);
  }
}

.imgBox img {
  width: 100vw;
  max-width: 100%;
  height: 100%;
  min-height: 70vh;
  object-fit: cover;
  @media (--viewportMedium) {
    height: 80vh;
  }
  @media (--viewportLarge) {
    height: 90vh;
  }
}
