@import '../../../styles/customMediaQueries.css';

.sliderProductsSection {
  background: linear-gradient(180deg, rgb(255 255 255) 2%, rgb(184 229 251 / 72%) 100%) !important;
}
.sliderProductsSection > div:first-child {
  max-width: 100vw;
  width: 100%;
  height: 100vh;
  max-height: 740px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportLargeWithPaddings) {
    max-width: 1440px;
  }
}
.sliderProductsSection .sliderContainer {
  width: 100%;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}
.sliderProductsSection .sliderContainer :global(.slick-slider) {
  display: flex;
  width: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  margin-bottom: 30px;
  max-width: 300px;
  min-width: 300px;
  max-height: 600px;
    min-height: 600px;
  background-color: var(--colorWhite);
  border-radius: 30px;
  @media (--viewportLarge) {
    max-width: 400px;
    min-width: 400px;
    }
  &:hover {
    cursor: pointer;
  }
}
.container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.imgBox {
  width: 100%;
  max-height: 360px;
  height: 360px;
}
.contentBox {
  width: 100%;
  padding: 0 24px;
}
.contentBox p {
  margin: 0;
  padding: 0;
  text-align: center;
  max-height: 170px;
  overflow-y: auto;
}
.contentBox p:first-of-type {
  margin-bottom: 8px;
  color: rgb(30, 50, 101);
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  text-transform: uppercase;
}
.contentBox p:nth-child(2) {
  margin-bottom: 8px;
  color: var(--colorBlack10);
  font-weight: 800;
  font-size: 14px;
}
.contentBox p:last-of-type {
  color: var(--colorBlack10);
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  text-align: justify;
}
:global(.slick-initialized) :global(.slick-slide) {
  display: flex;
  align-items: center;
  justify-content: center;
}
.sliderProducts :global(.slick-track) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 100%;
}
.peopleSaySection {
  background-color: var(--colorWhite);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 54px;

  @media (--viewportMedium) {
    width: 100%;
    max-height: 1012px;
    padding: 100px 0;
  }
}
.peopleSaySection > div:first-child {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.peopleSaySection .peopleSayBoxContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  gap: 30px;
  width: 80%;

  @media (--viewportMedium) {
    flex-direction: row;
    width: 100%;
    gap: 4%;
  }
}
.peopleSaySection .peopleSayItem {
  background-color: #edf3f6;
  border-radius: 10px 10px 10px 10px;
  overflow: hidden;
  padding: 30px 24px 16px 24px;
  flex: 0 0 29%;
  box-shadow: 0px 47px 48px 0px #294d5e26;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.peopleSayItem::before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAKCAYAAABBq/VWAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQyIDc5LjE2MDkyNCwgMjAxNy8wNy8xMy0wMTowNjozOSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDoxMDE0QkQxMERCQzUxMUU4OUZCOUEyMzBENDU4QjZBMCIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDoxMDE0QkQxMURCQzUxMUU4OUZCOUEyMzBENDU4QjZBMCI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOjEwMTRCRDBFREJDNTExRTg5RkI5QTIzMEQ0NThCNkEwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjEwMTRCRDBGREJDNTExRTg5RkI5QTIzMEQ0NThCNkEwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+XTLSEAAAAT9JREFUeNqk0z9IQlEUx/Fr9IcaEiLSKIz+DUVO0mzYUlRL5eDcEDQFEbQXDbk0NeSmcw4uQQktNTS8CIoMDMGooXBpqKF/9D1xhJBrKh74wBvuPee++3vPZUaDLcaYLoxhCgtowhb28GWqKy+2EUEWB0gj42JI6eIhzGENjTjDDs4tjeWAM1jHCO51kDQvFBfZhvwteasVBHSgbHxGMzxw4wXH2MeprUmlIcXyYRaD6MCHDrvSa/n8b7MMudHXfsMtYjgy9dUyFtErB5AhYQ1tHH704wEJRPFdZeMBrGIeDcjhAo7tuuQ6NjXQViSRwqGlcRvCmt0E7hDHbi2ZLGFDQ5aA83j63WdMH7rRjktdVzZ4+UdeVbmSRtOYRI9eYUa/qpMKwbtliMPDO671+5bTPNYRuuQxjJD+3J0/AgwAHABDPejBW8MAAAAASUVORK5CYII=);
  zoom: 1;
  filter: alpha(opacity=5);
  -webkit-opacity: 0.05;
  -moz-opacity: 0.05;
  opacity: 0.05;
}
.peopleSaySection .peopleSayItem div p:first-of-type {
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  line-height: 32.4px;
  color: #294d5e;
}
.peopleSaySection .peopleSayItem p:last-of-type {
  font-size: 18px;
  font-weight: var(--fontWeightSemiBold);
  line-height: 32.4px;
  text-align: center;
  color: var(--colorBlack10);
}
.peopleSayTitle {
  font-size: 30px;
  font-weight: var(--fontWeightLight);
  line-height: normal;
  text-align: center;
  color: var(--colorBlack10);
  padding-bottom: 40px;
  max-width: 314px;
  margin: 0;
  padding-top: 0;
  @media (--viewportMedium) {
    font-size: 48px;
    max-width: 100%;
    font-weight: var(--fontWeightBold);
  }
}
.peopleSaySubTitle {
  font-size: 16px;
  font-weight: var(--fontWeightBold);
  line-height: normal;
  text-align: center;
  color: #5e94b0;
  padding: 0px;
  margin: 0;
}
.peopleSayLinkButton {
  margin-top: 48px;
  width: 198px;
  height: 56px;
  background: linear-gradient(180deg, rgb(231 255 255) 2%, rgb(255 255 255) 72%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 16px;
  border-color: var(--colorBlack10);
  border-width: 1px;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: inset 0 0px 0 50px #5e94b09c;

  &:hover {
    box-shadow: inset 0 0px 0 0 #294d5e;
    cursor: pointer;
  }
}
.peopleSayLinkButton a {
  color: var(--colorBlack10);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: var(--fontWeightBold);
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
.brandSection {
  background-color: var(--colorWhite);
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 81px;
  @media (--viewportMedium) {
    width: 100%;
    height: 100vh;
    max-height: 350px;
    padding: 27px 0;
  }
}
.brandSection > div:first-child {
  max-width: 100vw;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportLargeWithPaddings) {
    max-width: 1440px;
  }
}
.brandSection .brandSliderContainer {
  width: 100%;
  margin-bottom: 10px;
  @media (--viewportMedium) {
    font-size: 20px;
  }
}
.brandSection .brandSliderContainer :global(.slick-slider) {
  display: flex;
}
.sliderImgBrandContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
:global(.slick-slide) .sliderImgBrandContainer img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  min-width: 100%;
  object-fit: cover;
}
.brandSliderContainer :global(.slick-track) {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}
.brandSliderContainer > :global(.slick-next:before),
:global(.slick-prev:before) {
  color: #222b30;
  opacity: 1;
}
:global(.slick-next) {
  right: 30px;
  @media (--viewportLarge) {
    right: 0;
  }
}
:global(.slick-prev) {
  left: 20px !important;
  z-index: 9 !important;
  @media (--viewportLarge) {
    left: 0 !important;
  }
}
