@import '../../../../styles/customMediaQueries.css';

.root {
  border-top: 1px solid var(--colorGrey100);
}

.footer {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 24px 32px;
}

.footer ul {
  list-style-type: none;
  padding-inline-start: 0px;
  margin-top: 1px;
}

.linkList {
  list-style-type: none;
  padding-left: 12px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.grid {
  display: flex;
  align-items: stretch;
  width: 100%;
  flex-direction: column;
  gap: 14px;
  margin-top: 20px;

  @media (--viewportMedium) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    gap: 30px;
  }
  
  @media (--viewportLarge) {
    justify-content: flex-end;
  }
}

.detailsInfo {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.slogan {
  font-size: 16px;
  line-height: 24px;
}
.sloganMobile {
  grid-area: details;
  padding-bottom: 24px;

  @media (--viewportLarge) {
    display: none;
  }
}
.sloganDesktop {
  display: none;

  @media (--viewportLarge) {
    display: block;
  }
}

.logo {
  grid-area: logo;
}

.copyright {
  display: inline-block;
  font-size: 12px;
  line-height: 16px;
  color: var(--colorGrey500);
  margin-top: 0px;
  @media (--viewportMedium) {
    margin-top: 24px;
  }
}

.icons {
  margin-top: 0px;
}

.icon {
  font-size: 28px;
  margin: 15px 15px 0px 0px;
  padding: 10px 15px;
}

.logoLink {
  display: inline-block;
  padding: 0;

  &:hover {
    text-decoration: none;
  }
}
.logoImage {
  height: 24px;
  width: auto;
  max-width: 188px;
  object-fit: contain;
  object-position: center;
}

.footerImg {
  width: 100%;
}
.footerImgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (--viewportMedium) {
    margin-bottom: 0;
    align-items: flex-start;
  }
}
.footerImgContainer a {
  margin-bottom: 0;
  @media (--viewportMedium) {
    margin-bottom: 20px;
    padding: 12px 10px !important;
  }
}
