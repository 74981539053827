@import '../../../styles/customMediaQueries.css';

.landingPageContactSection {
  background-image: linear-gradient(
      180deg,
      rgba(49, 128, 192, 0.2) 0%,
      rgba(49, 128, 192, 0.6) 100%
    ),
    url('../../ContactUsPage/images/doctor-02.jpg');
  overflow: hidden;
  box-shadow: 0px 30px 80px -30px rgba(102, 141, 186, 0.7);
  background-size: cover;
}
.landingPageContactSection > div:first-child {
  max-width: 1440px;
  width: 100%;
  margin: 30px auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 40px;
  color: var(--colorWhite);
  font-weight: var(--fontWeightBold);
}
.description {
  font-size: 16px;
  color: var(--colorWhite);
  font-weight: var(--fontWeightRegular);
  margin: 0;
  padding: 0;
  max-width: 900px;
  text-align: center;
}
