@import '../../../styles/customMediaQueries.css';
.socialMediaSection {
  padding: 60px 0;
}
.socialMediaSection > div:first-child {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.socialItems {
  padding: 0 24px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;
  justify-items: center;
  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
    padding: 0 36px;
  }
}
.socialItems iframe {
  width: 100%;
  height: 320px;
}

.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-bottom: 60px;
  padding: 0 24px;
  @media (--viewportMedium) {
    gap: 20px;
    padding: 0;
  }
}
.titleContainer p {
  font-size: 24px;
  font-weight: var(--fontWeightBold);
  color: var(--colorBlack10);
  margin: 0;
  padding: 0;
  text-transform: capitalize;
  @media (--viewportMedium) {
 text-align: center;
 font-size: 38px;

  }
}
.titleContainer svg {
  width: 50px;
  height: 50px;
}
