@import '../../../styles/customMediaQueries.css';

.subscribeNews {
  background-color: var(--colorWhite);
}
.subscribeNews > div:first-child {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 0 30px 0;
  @media (--viewportMedium) {
    flex-direction: row;
    padding: 60px 0;
  }
}
.subscribeContentBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  @media (--viewportMedium) {
    align-items: flex-start;
  }
}
.subscribeNews div p:first-of-type {
  margin: 0 0 0 0;
  font-size: 24px;
  line-height: 50px;
  color: var(--colorBlack10);
  font-weight: var(--fontWeightBold);
  text-align: center;
  @media (--viewportMedium) {
    font-size: 56px;
    text-align: left;
    margin: 0 0 10px 0;
  }
}
.subscribeNews div p:last-of-type {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
  color: #5e94b0;
  font-weight: var(--fontWeightRegular);
  text-align: center;
  padding: 0 24px;
  @media (--viewportMedium) {
    padding: 0;
    text-align: left;
    line-height: 32px;
  }
}
.subscribeInputContainer {
  background-image: linear-gradient(
      180deg,
      rgba(49, 128, 192, 0.2) 0%,
      rgba(49, 128, 192, 0.6) 100%
    ),
    url('../../ContactUsPage/images/newsletter_1-scaled.jpeg');
  overflow: hidden;
  box-shadow: 0px 30px 80px -30px rgba(102, 141, 186, 0.7);
  background-size: cover;
  background-position: center;
  border-radius: 0px;
  width: 100%;
  height: 200px;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (--viewportMedium) {
    border-radius: 6px;
  }
}
.subscribeInputContainer form {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.subscribeInputContainer form button {
  margin-top: 10px;
  width: 100%;
  height: 46px;
  background: linear-gradient(180deg, rgb(187 207 224) 2%, rgb(184, 212, 223) 72%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 6px;
  border-color: var(--colorWhite);
  border-width: 1px;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: inset 0 0px 0 50px #98b3cf4a;
  color: var(--colorWhite);

  &:hover {
    box-shadow: inset 0 0px 0 0 #4d7398;
    cursor: pointer;
  }
}
