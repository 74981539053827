@import '../../../styles/customMediaQueries.css';
.root {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--colorWhite);
  @media (--viewportMedium) {
    flex-direction: row;
    padding: 40px 0;
  }
}
.leftContainer {
  width: 100%;
  margin-right: 5.5%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 24px;
  padding-right: 24px;
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  @media (--viewportMedium) {
    width: 47.25%;
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 3%;
    padding-right: 0;
  }
  @media (--viewportLarge) {
    padding-left: 12%;
  }
}
.leftContainer p:first-of-type {
  color: #00c5fe99;
  font-size: 18px;
  font-weight: 800;
  line-height: 24px;
  margin: 0;
  padding: 0;
}
.leftContainer p:nth-child(2) {
  color: var(--colorBlack10);
  font-size: 32px;
  font-weight: 800;
  line-height: 40px;
  margin: 0 0 16px 0;
  padding: 0;
  @media (--viewportMedium) {
    font-size: 48px;
    line-height: 53px;
  }
}
.leftContainer p:nth-child(3) {
  color: var(--colorBlack10);
  font-size: 14px;
  line-height: 25px;
  font-weight: var(--fontWeightMedium);
  margin: 0;
  padding: 0;
  @media (--viewportMedium) {
    font-size: 16px;
  }
}
.rightContainer {
  width: 100%;
  border-radius: 30px 0 0 15vw;
  overflow: hidden;
  border-color: #51bdef;
  border-style: solid;
  border-left-width: 2vw;
  border-bottom-width: 0;
  border-right-width: 0;
  border-top-width: 0;
  @media (--viewportMedium) {
    width: 47.25%;
  }
}
.rightContainer img {
  width: 100%;
}
.namedLink {
  margin-top: 16px;

  width: 198px;
  height: 46px;
  background: linear-gradient(180deg, rgb(231 255 255) 2%, rgb(255 255 255) 72%) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: solid;
  border-radius: 4vw;
  border-color: #00c5fe99;
  border-right-width: 4px;
  border-bottom-width: 0px;
  border-left-width: 4px;
  border-top-width: 0;
  transition: all 0.3s ease;
  transform: scale(1);
  box-shadow: inset 0 0px 0 50px #baedff;
  @media (--viewportMedium) {
    height: 56px;
    border-radius: 1vw 1vw 1vw 1vw;
  }
  &:hover {
    box-shadow: inset 0 0px 0 0 #baedff;
    cursor: pointer;
  }
}
.namedLink a {
  color: var(--colorBlack10);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: var(--fontWeightSemiBold);
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}
.rootTwo {
  background-color: var(--colorWhite);
}
.rootTwo > div:first-child {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto 40px auto;
  padding-top: 60px;
  padding-bottom: 60px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  color: rgba(255, 255, 255, 0.75);
  background: linear-gradient(180deg, rgb(81 189 239) 2%, rgb(184 229 251) 100%) !important;
  border-radius: 8px;

  @media (--viewportLarge) {
    flex-direction: row;
  }
}

.contentText {
  @media (--viewportLarge) {
    border-right: 2px dashed var(--colorWhite);
  }
}
.rootTwo > div:first-child > div:first-of-type {
  width: 100%;
  padding: 20px 24px;
  color: var(--colorWhite) !important;
  font-size: 14px;
  @media (--viewportLarge) {
    flex-direction: row;
    width: 26%;
    padding: 40px;
  }
}

.rootTwo > div:first-child > div:last-of-type {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 24px 0 24px;
  justify-content: space-around;
  width: 100%;
  @media (--viewportLarge) {
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    width: 76%;
    padding: 0;
    flex-wrap: nowrap;
  }
}
.contentContainer {
}
.contentBox::before {
  content: '';
  display: block;
  left: -16px;
  right: -16px;
  bottom: -16px;
  top: -16px;
  border-radius: -18%;
  background-clip: padding-box;
  position: absolute;
  border: 2px dashed var(--colorWhite);
  zoom: 1;
  filter: alpha(opacity=10);
  opacity: 0.3;
  transition: all 0.3s ease;
  border-radius: 50%;
}
.contentBox:hover::before {
  zoom: 1;
  filter: alpha(opacity=40);
  opacity: 40;
}
.contentBox {
  position: relative;
  width: 150px;
  max-height: 150px;
  height: 150px;
  max-width: 150px;
  flex: 1 1 50%;

  @media (--viewportLarge) {
    flex: none;
  }
  &:hover {
    cursor: pointer;
  }
}
.contentBox:first-of-type {
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-image: url('../images/introducer_pic2.png');
}
.contentBox:nth-child(2) {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: url('../images/head_model_overview3.png');
}
.contentBox:nth-child(3) {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: url('../images/praxis_overview.png');
}
.contentBox:last-of-type {
  border-radius: 50%;
  background-position: center;
  background-size: cover;
  background-image: url('../images/workStationNew.png');
}
