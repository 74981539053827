@import '../../../../styles/customMediaQueries.css';

.root {
  display: flex;
  width: 100%;
}

.searchSubmit {
  outline: none;
  border: none;
  background-color: #176595de;
  width: 50px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;

  &:hover {
    cursor: pointer;
  }
}
.searchSubmit svg {
  transform: rotate(270deg);
  fill: var(--colorWhite);
}
.searchSubmit svg g {
  stroke: var(--colorWhite);
}
.desktopInput {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--colorWhite);
  color: var(--colorBlack10);
  font-weight: var(--fontWeightMedium);
}
.desktopInput:focus {
  box-shadow: none;
  background-color: var(--colorWhite);
}
.desktopInput::placeholder {
  font-weight: var(--fontWeightRegular);
}
