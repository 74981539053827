@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-color: var(--colorWhite);


}

.sectionContent {
  padding: 6px 0;
  position: relative;
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
